import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import scene from "../scene";
export default function StreetLamp(name, id, positionX, positionY, positionZ) {
  let gltfLoader = new GLTFLoader();
  gltfLoader.load("/model/ludeng2.gltf", (ludeng) => {
    let cityArray = ["LED"];
    //创建显示器贴图
    const textureloader = new THREE.TextureLoader();
    const textureloaders = textureloader.load("/texture/xianshiqi.jpg");
    let video = document.createElement("video");
    // console.log(video);
    video.src = "/texture/13s.mp4"; // 设置视频地址
    video.autoplay = "autoplay"; //要设置播放
    video.loop = "loop"; //循环播放
    video.muted = true;
    // video对象作为VideoTexture参数创建纹理对象
    var texture = new THREE.VideoTexture(video);
    // texture.needsUpdate = true;

    texture.center.set(0.5, 0.5);
    textureloaders.center.set(0.5, 0.5);
    texture.rotation = Math.PI / 1;
    textureloaders.rotation = Math.PI / 1;
    const ludengcaizhi = new THREE.MeshPhongMaterial({
      // map: texture,
      side: THREE.DoubleSide,
    });
    ludeng.scene.traverse(function (child) {
      // console.log(child);
      if (cityArray.includes(child.name)) {
        // console.log(child);
        child.material = ludengcaizhi;
      }
    });

    ludeng.scene.scale.set(0.03, 0.03, 0.03);
    ludeng.scene.name = name;
    ludeng.scene.number = id;
    ludeng.scene.position.x = positionX;
    ludeng.scene.position.y = positionY + 24;
    ludeng.scene.position.z = positionZ;
    scene.add(ludeng.scene);
    // spotLights(id, positionX, positionY, positionZ);
  });
}
// let gltfLoader = new GLTFLoader();
// gltfLoader.load("/model/ludeng.glb", (ludeng) => {
//   let cityArray = ["ViewingScreen"];
//   //创建显示器贴图
//   const textureloader = new THREE.TextureLoader();
//   const textureloaders = textureloader.load("/texture/xianshiqi.jpg");
//   // let video = document.createElement('video');
//   //   video.src = "/texture/normalvideo.mp4"; // 设置视频地址
//   //   video.autoplay = "autoplay"; //要设置播放
//   //   video.loop = "loop"; //循环播放
//   //   // video.muted='muted'
//   //   // video对象作为VideoTexture参数创建纹理对象
//   // var texture = new THREE.VideoTexture(video)
//   textureloaders.center.set(0.5,0.5)
//   textureloaders.rotation=Math.PI/1
//   const ludengcaizhi = new THREE.MeshPhongMaterial({
//     map: textureloaders,
//   });
//   ludeng.scene.traverse(function (child) {
//     // console.log(child);
//     if (cityArray.includes(child.name)) {
//       child.material = ludengcaizhi;
//     }
//   });

//   ludeng.scene.scale.set(0.1, 0.1, 0.1);
//   ludeng.scene.name = name;
//   ludeng.scene.number=id
//   ludeng.scene.position.x = positionX;
//   ludeng.scene.position.z = positionZ;
//   ludeng.scene.position.y = positionY;
//   scene.add(ludeng.scene);
//   spotLights(
//     scene,
//     id,
//     positionX,
//     positionY + 110,
//     positionZ,
//     positionX,
//     positionY + 15,
//     positionZ
//   );
// });
