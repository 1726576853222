import * as THREE from "three";
const renderer = new THREE.WebGLRenderer({
  // 是否执行抗锯齿。默认为false.
  antialias: true,
  //  canvas是否包含alpha (透明度)。默认为 false
  alpha: true,
});
renderer.setSize(window.innerWidth, window.innerHeight);
// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
// renderer.setClearColor(new THREE.Color("#32373E"), 1);

renderer.outputEncoding = THREE.sRGBEncoding; //编码设置
renderer.toneMapping = THREE.ACESFilmicToneMapping; //色调映射
// renderer.toneMapping = THREE.ReinhardToneMapping;
// renderer.toneMapping = THREE.CineonToneMapping;
// renderer.toneMapping = THREE.LinearToneMapping;
renderer.toneMappingExposure = 0.5; //曝光程度
export default renderer;
