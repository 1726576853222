import * as THREE from "three";
import gsap from "gsap";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import scene from "../scene";
import carList from './carList'
export default function Car (){
    let gltfLoader = new GLTFLoader();
    carList.forEach(item=>{
      gltfLoader.load(item.url, (car) => {
        car.scene.name = "car";
        car.scene.position.set(item.positionX, item.positionY, item.positionZ);
        car.scene.scale.set(item.scale,item.scale,item.scale);
        car.scene.rotation.set(item.rotationX,item.rotationY,item.rotationZ)
        scene.add(car.scene);
        gsap.to(car.scene.position, {
          x:item.gsapX,
          y:item.gsapY,
          z:item.gsapZ,
          // 完成时间
          duration: item.duration,
          // 重复次数 无限次就是-1
          repeat: -1,
          // 往返运动
          // yoyo: true,
          // 延迟运动
          delay:item.delay,
          // 运动速度
          ease: "none",
          onComplete: () => {
            // console.log("动画完成");
          },
          onStart: () => {
            // console.log("动画开始");
          },
        });
      }); 
    })
  
}