let streetLampList = [
  {
    name: "ludeng",
    id: 1,
    positionX: -100,
    positionY: -10,
    positionZ: -1040,
  },
  {
    name: "ludeng",
    id: 2,
    positionX: -105,
    positionY: -10,
    positionZ: -910,
  },
  {
    name: "ludeng",
    id: 3,
    positionX: -110,
    positionY: -10,
    positionZ: -840,
  },
  {
    name: "ludeng",
    id: 4,
    positionX: -115,
    positionY: -10,
    positionZ: -670,
  },
  {
    name: "ludeng",
    id: 5,
    positionX: -120,
    positionY: -10,
    positionZ: -500,
  },
  {
    name: "ludeng",
    id: 6,
    positionX: -125,
    positionY: -10,
    positionZ: -330,
  },
  {
    name: "ludeng",
    id: 7,
    positionX: -130,
    positionY: -10,
    positionZ: -265,
  },
  {
    name: "ludeng",
    id: 8,
    positionX: -135,
    positionY: -10,
    positionZ: -160,
  },
  {
    name: "ludeng",
    id: 9,
    positionX: -135,
    positionY: -10,
    positionZ: -50,
  },
  {
    name: "ludeng",
    id: 10,
    positionX: -135,
    positionY: -10,
    positionZ: 68,
  },
  {
    name: "ludeng",
    id: 11,
    positionX: -135,
    positionY: -10,
    positionZ: 200,
  },
  {
    name: "ludeng",
    id: 12,
    positionX: -135,
    positionY: -10,
    positionZ: 400,
  },
  {
    name: "ludeng",
    id: 13,
    positionX: -135,
    positionY: -10,
    positionZ: 550,
  },
  {
    name: "ludeng",
    id: 14,
    positionX: -135,
    positionY: -10,
    positionZ: 700,
  },
  {
    name: "ludeng",
    id: 15,
    positionX: -135,
    positionY: -10,
    positionZ: 850,
  },
  {
    name: "ludeng",
    id: 16,
    positionX: 30,
    positionY: -10,
    positionZ: -1040,
  },
  {
    name: "ludeng",
    id: 17,
    positionX: 23,
    positionY: -10,
    positionZ: -840,
  },
  {
    name: "ludeng",
    id: 18,
    positionX: 18,
    positionY: -10,
    positionZ: -670,
  },
  {
    name: "ludeng",
    id: 19,
    positionX: 12,
    positionY: -10,
    positionZ: -500,
  },
  {
    name: "ludeng",
    id: 20,
    positionX: 7,
    positionY: -10,
    positionZ: -330,
  },
  {
    name: "ludeng",
    id: 21,
    positionX: 4,
    positionY: -10,
    positionZ: -265,
  },
  {
    name: "ludeng",
    id: 22,
    positionX: 0,
    positionY: -10,
    positionZ: -160,
  },
  {
    name: "ludeng",
    id: 23,
    positionX: -2,
    positionY: -10,
    positionZ: -50,
  },
  {
    name: "ludeng",
    id: 24,
    positionX: -2,
    positionY: -10,
    positionZ: 68,
  },
  {
    name: "ludeng",
    id: 25,
    positionX: -2,
    positionY: -10,
    positionZ: 200,
  },
  {
    name: "ludeng",
    id: 26,
    positionX: -3,
    positionY: -10,
    positionZ: 400,
  },
  {
    name: "ludeng",
    id: 27,
    positionX: -4,
    positionY: -10,
    positionZ: 550,
  },
  {
    name: "ludeng",
    id: 28,
    positionX: -4,
    positionY: -10,
    positionZ: 700,
  },
  {
    name: "ludeng",
    id: 29,
    positionX: -4,
    positionY: -10,
    positionZ: 850,
  },
];

export default streetLampList;
