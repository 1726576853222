import * as THREE from "three";
import scene from "../scene";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import mapMaterial from "../material/mapMaterial";
import modifyCityMaterial from "../material/modifyCityMaterial";
function Map() {
  //////////////创建场景
  const gltfLoader = new GLTFLoader();
  //材质
  gltfLoader.load("/model/zhengzhouzzdd3.glb", (gltf) => {
    // console.log(gltf);
    gltf.scene.scale.set(1, 3, 1);
    scene.add(gltf.scene);
    gltf.scene.name = "zhengzhouzzdd";
    //遍历整个场景找到对应的对象
    console.log(gltf.scene);
    // gltf.scene.position.x=-100
    gltf.scene.traverse(function (child) {
      if (
        child.name.indexOf("花坛") != -1 ||
        child.name.indexOf("Cube") != -1||
        child.name.indexOf("立方体") != -1||
        child.name.indexOf("柱体") != -1
      ) {
        //为模型创建包围线框
        var edgeMaterial = new THREE.LineBasicMaterial({
          color: 0xffffffff,
          linewidth: 5,
        });
        // console.log(child);
        var edges = new THREE.EdgesGeometry(child.geometry, 45);
        var line = new THREE.LineSegments(edges, edgeMaterial);
        // console.log(child);
        // child.add(line);
        //城市模型材质,以及动画效果
        // const cityMaterial = new THREE.MeshBasicMaterial({
        //   color: new THREE.Color(0x0c0e33),
        // });
        // child.material = cityMaterial;
        // mapMaterial(child);
      }
    });

    const cityArray = ["newzhengz"];
    gltf.scene.traverse(function (child) {
      if (cityArray.includes(child.name)) {
        //为模型创建包围线框
        var edgeMaterial = new THREE.LineBasicMaterial({
          color: 0xfffffff,
          linewidth:10,
        });
        var edges = new THREE.EdgesGeometry(child.geometry, 45);
        var line = new THREE.LineSegments(edges, edgeMaterial);
        child.add(line);
        //城市模型材质,以及动画效果
        const cityMaterial = new THREE.MeshBasicMaterial({
          color: new THREE.Color(0x0c0e33),
        });
        child.material = cityMaterial;
        modifyCityMaterial(child);
      }
      if (
        child.name.indexOf("花坛") != -1 ||
        child.name.indexOf("Cube") != -1 ||
        child.name.indexOf("立方体") != -1 ||
        child.name.indexOf("柱体") != -1
      ) {
        //为模型创建包围线框
        var edgeMaterial = new THREE.LineBasicMaterial({
          color: 0xffffffff,
          linewidth: 5,
        });
        // console.log(child);
        var edges = new THREE.EdgesGeometry(child.geometry, 45);
        var line = new THREE.LineSegments(edges, edgeMaterial);
        // console.log(child);
        // child.add(line);
        //城市模型材质,以及动画效果
        // const cityMaterial = new THREE.MeshBasicMaterial({
        //   color: new THREE.Color(0x0c0e33),
        // });
        // child.material = cityMaterial;
        // mapMaterial(child);
      }
    });
  });
}
export default Map;
