let carList = [
  {
    url: "/model/car004.gltf",
    positionX: -20,
    positionY: -15,
    positionZ: -1040,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
    scale: 0.05,
    gsapX: -40,
    gsapY: -15,
    gsapZ: 1040,
    duration: 30,
    delay: 0,
  },
  {
    url: "/model/car004.gltf",
    positionX: -10,
    positionY: -15,
    positionZ: -1040,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
    scale: 0.05,
    gsapX: -40,
    gsapY: -15,
    gsapZ: 1040,
    duration: 30,
    delay: 1,
  },
  {
    url: "/model/car004.gltf",
    positionX: -20,
    positionY: -10,
    positionZ: -1040,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
    scale: 0.05,
    gsapX: -25,
    gsapY: -15,
    gsapZ: 1040,
    duration: 30,
    delay: 2,
  },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -10,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -20,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 3,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -35,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 4,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -10,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -30,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 5,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -15,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -40,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 6,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -10,
  //   positionY: -15,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -40,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 7,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -25,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 8,
  // },
  //{
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -35,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 10,
  // }, {
  //   url: "/model/car004.gltf",
  //   positionX: -10,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -30,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 11,
  // }, {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -15,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -40,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 12,
  // },
  // {
  //   url: "/model/car004.glb",
  //   positionX: -10,
  //   positionY: -15,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.2,
  //   gsapX: -40,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 13,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -25,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 14,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -10,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -20,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 15,
  // }, {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -35,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 16,
  // }, {
  //   url: "/model/car004.gltf",
  //   positionX: -10,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -30,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 17,
  // }, {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -15,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -40,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 18,
  // },
  // {
  //   url: "/model/car004.glb",
  //   positionX: -10,
  //   positionY: -15,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.2,
  //   gsapX: -40,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 19,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -25,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 20,
  // }, {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -30,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 21,
  // }, {
  //   url: "/model/car004.gltf",
  //   positionX: -20,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -35,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 22,
  // }, {
  //   url: "/model/car004.gltf",
  //   positionX: -10,
  //   positionY: -10,
  //   positionZ: -1040,
  //   rotationX: 0,
  //   rotationY: 0,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -30,
  //   gsapY: -15,
  //   gsapZ: 1040,
  //   duration: 30,
  //   delay: 23,
  // },
  // {
  //   url: "/model/car1.glb",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.5,
  //   gsapX: -70,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 0,
  // },

  // {
  //   url: "/model/car004.glb",
  //   positionX: -115,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.2,
  //   gsapX: -65,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 2,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -70,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 4,
  // },
  // {
  //   url: "/model/car002.gltf",
  //   positionX: -115,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.2,
  //   gsapX: -65,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 5,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -75,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 6,
  // },
  // {
  //   url: "/model/car1.glb",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.5,
  //   gsapX: -70,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 7,
  // },
  // {
  //   url: "/model/car004.glb",
  //   positionX: -115,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.2,
  //   gsapX: -65,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 8,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -120,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -90,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 9,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -115,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -65,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 10,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -75,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 11,
  // }, {
  //   url: "/model/car1.glb",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.5,
  //   gsapX: -70,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 12,
  // },
  // {
  //   url: "/model/car004.glb",
  //   positionX: -115,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.2,
  //   gsapX: -65,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 13,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -70,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 14,
  // },
  // {
  //   url: "/model/car002.gltf",
  //   positionX: -115,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.2,
  //   gsapX: -65,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 15,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -75,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 16,
  // },
  // {
  //   url: "/model/car1.glb",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.5,
  //   gsapX: -70,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 17,
  // },
  // {
  //   url: "/model/car004.glb",
  //   positionX: -115,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.2,
  //   gsapX: -65,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 18,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -120,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -90,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 19,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -115,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -65,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 20,
  // },
  // {
  //   url: "/model/car004.gltf",
  //   positionX: -110,
  //   positionY: -15,
  //   positionZ: 1040,
  //   rotationX: 0,
  //   rotationY: Math.PI,
  //   rotationZ: 0,
  //   scale: 0.05,
  //   gsapX: -75,
  //   gsapY: -15,
  //   gsapZ: -1040,
  //   duration: 30,
  //   delay: 21,
  // },
];
export default carList;
