import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
// 初始化场景
const scene = new THREE.Scene();
// 环境光
const light = new THREE.AmbientLight(0xadadad, 1); // soft white light
scene.add(light);
// 平行光源
const directionalLight = new THREE.DirectionalLight(0xffffff, 1.0);
directionalLight.position.set(100, 100, 0);
scene.add(directionalLight);
//创建天空盒子
const CubeTextureLoader = new THREE.CubeTextureLoader().setPath("./texture/");
const TextCube = CubeTextureLoader.load([
  "1.jpg",
  "2.jpg",
  "3.jpg",
  "4.jpg",
  "5.jpg",
  "6.jpg",
]);
scene.background = TextCube;
// scene.environment = TextCube;
// const rgbeLoader = new RGBELoader();
// let texture = rgbeLoader.loadAsync(require("./hdr/2k.hdr"));
// texture.then((texture) => {
//   texture.mapping = THREE.EquirectangularReflectionMapping;
//   scene.background = texture;
//   scene.environment = texture; // 给场景所有物体添加默认的环境贴图
// });
export default scene;
